<template>
    <v-row no-gutters>
        <v-col cols="12" sm="12" md="4">
            <label class="f-label">{{ $t(label) }}</label>
        </v-col>
        <v-col  cols="12" sm="12" md="8">
            <div class="more-inputs-in-row">
                <v-text-field
                    :value="content"
                    @input="handle"                    
                    hide-details
                    required
                    outlined
                    dense
                    type="number"
                    class="cust-inputnumber"
                    min="0"
                    :rules="rules"
                >
                </v-text-field>              
            </div>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "FieldEditNumber",
    props: {
        label: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: null
        },
        rules: null
    },
    data() {
        return {
            content: this.value
        }
    },
    methods: {
        handle(e) {
            this.$emit('input', e);
        },
        filter: function(evt) {
            
            //How to use
            // <v-text-field @keypress="filter(event)">

            var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
            if(key == ',' && this.value.includes(','))
            {
                evt.preventDefault();
                return false;
            }
            
            var regex = new RegExp("^[0-9]*,?[0-9]*$");
            
            if (!regex.test(key)) {
                evt.preventDefault();
                return false;
            }      
        }
    }
};
</script>

